<template>
    <div>
        <div v-if="pageState === PAGE_STATES.DASHBOARD">
            <component :is="activeComponent" />
        </div>
        <div v-else>
            <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
            <div class="content-wrapper">
                <div class="content">
                    <div v-if="pageState === PAGE_STATES.PAGE_LOADING">
                        <div class="d-flex justify-center align-center">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </div>
                    </div>
                    <div v-else-if="pageState === PAGE_STATES.ERROR">
                        <div class="d-flex justify-center align-center">
                            <h2>При загрузке данных произошла ошибка</h2>
                        </div>
                    </div>

                    <div v-else-if="pageState === PAGE_STATES.INITIALIZING" class="content mt-12">
                        <div class="d-flex justify-center align-center">
                            <div class="d-flex justify-center align-center" style="flex-direction: column;">
                                <h2>
                                    Изучаем привычки ваших клиентов. Это может занять несколько десятков
                                    минут. Мы напишем вам на e-mail, когда все будет готово.
                                </h2>
                                <div style="width: 400px">
                                    <v-progress-linear
                                        indeterminate
                                        color="main"
                                        class="mt-10"
                                    ></v-progress-linear>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="pageState === PAGE_STATES.DATA_LOADING" class="content mt-12">
                        <div class="d-flex justify-center align-center">
                            <div class="d-flex justify-center align-center" style="flex-direction: column;">
                                <h2>Данные загружаются. Мы напишем вам на e-mail, когда все будет готово.</h2>
                                <div style="width: 400px">
                                    <v-progress-linear
                                        indeterminate
                                        color="main"
                                        class="mt-10"
                                    ></v-progress-linear>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

import fb from '@/plugins/firebase'
const GCB2Main = () => import('@/views/GCB2/Main.vue')
const ReturnabilityDashboard = () => import('@/views/GCB2/ReturnabilityDashboard')
const MarketingReport = () => import('@/views/GCB2/MarketingReport')
const SimpleReports = () => import('@/views/GCB2/SimpleReports')
const Kpi = () => import('@/views/GCB2/Kpi')
const ChurnReport = () => import('@/views/GCB2/ChurnReport')
const StaffReport = () => import('@/views/GCB2/StaffReport')
const SameDayRecReport = () => import('@/views/GCB2/SameDayRecReport')
const FourhandsReport = () => import('@/views/GCB2/FourhandsReport')
const PersonaReport = () => import('@/views/GCB2/PersonaReport')
const DoctorReport = () => import('@/modules/DoctorReport/views/DoctorReport.vue')
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import { isNil } from 'lodash'
import { INIT } from '@root/src/store/chisai/GCB2/actionTypes'
import NotFound from '../NotFound.vue'
import { getWhatsappBasisSubsystemStatus } from '@api/chisai/GCB2'
import { useBillingStore } from '@/store/stores/billing'
const AvgCheckReport = () => import('@root/src/modules/AvgCheckReport/views/AvgCheckReport.vue')

const PAGE_STATES = {
    PAGE_LOADING: 'PAGE_LOADING',
    DASHBOARD: 'DASHBOARD',
    DATA_LOADING: 'DATA_LOADING',
    INITIALIZING: 'INITIALIZING',
    ERROR: 'ERROR',
}
export default {
    name: 'GCB2StartPage',
    components: { Breadcrumbs, ReturnabilityDashboard, GCB2Main, MarketingReport, SimpleReports },
    data: () => ({
        PAGE_STATES,
        isInit: 'pending',
        initRef: null,
        loadingRef: null,
        loading: null,
        pageState: null,
    }),
    props: {
        dashboardType: {
            type: String,
        },
    },
    computed: {
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        connector() {
            return this.project.connectors.find(con => con.connectorType === 'GCB2')
        },
        activeComponent() {
            if (this.dashboardType === 'main') return GCB2Main
            if (this.dashboardType === 'returnability') return ReturnabilityDashboard
            if (this.dashboardType === 'marketingReport') return MarketingReport
            if (this.dashboardType === 'simpleReports') return SimpleReports
            if (this.dashboardType === 'kpi') return Kpi
            if (this.dashboardType === 'churn') return ChurnReport
            if (this.dashboardType === 'staff') return StaffReport
            if (this.dashboardType === 'sameDayRec') return SameDayRecReport
            if (this.dashboardType === 'fourhandsReport') return FourhandsReport
            if (this.dashboardType === 'personaReport') return PersonaReport
            if (this.dashboardType === 'doctorReport') return DoctorReport
            if (this.dashboardType === 'avgCheckReport') return AvgCheckReport
            return NotFound
        },
        breadcrumbs() {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
            ]
        },
    },
    watch: {
        '$route.params.id': {
            immediate: true,
            async handler(oldVal, newVal) {
                //если проект изменился, ощищаем listener
                if (this.initRef) {
                    this.initRef.off()
                    this.loadingRef.off()
                }
                const projectId = this.$router.currentRoute.params.id

                //подписываемся на изменение инициализации GCB
                this.initRef = fb.database().ref(`/project/${projectId}/initChisai/GCB2`)
                this.loadingRef = fb.database().ref(`/project/${projectId}/loading`)
                this.initRef.on('value', data => {
                    this.isInit = data.val()
                    this.setPageState()
                })
                this.loadingRef.on('value', data => {
                    this.loading = data.val()
                    this.setPageState()
                })

                //Если GCB не инициализированн, инициализируем
                if (!this.connector && this.project.id !== 'loading') {
                    await this.$store.dispatch(`GCB2/${INIT}`, projectId)
                }
            },
        },
    },
    methods: {
        setPageState() {
            if (!isNil(this.loading)) {
                if (this.isInit === 'pending') {
                    this.pageState = PAGE_STATES.PAGE_LOADING
                } else if (this.isInit === true) {
                    this.pageState = PAGE_STATES.DASHBOARD
                } else if (this.loading.isError) {
                    this.pageState = PAGE_STATES.ERROR
                } else if (this.loading.status === true) {
                    this.pageState = PAGE_STATES.DATA_LOADING
                } else {
                    this.pageState = PAGE_STATES.INITIALIZING
                }
            } else {
                this.pageState = PAGE_STATES.PAGE_LOADING
            }
        },
    },
    async created() {
        const billingStore = useBillingStore()
        if (this.project.billingEnabled)
            await Promise.all([
                billingStore.fetchCurrentTariff(this.project.id),
                billingStore.fetchTariffs(this.project.id),
            ])
        if (!this.$store.state.whatsappStatus){
            const data = await getWhatsappBasisSubsystemStatus(this.project.id)  
            this.$store.state.whatsappStatus = data.data

        }
    },
}
</script>
<style lang="sass" scoped></style>
